import React, { useState, useEffect } from 'react';
import { QwestiveLargeBlack } from 'assets/QwestiveLogos/QwestiveLogos';
import { TwitterIcon, DiscordIcon, TelegramIcon } from 'assets/SocialIcons';
import { discordLink, twitterLink, telegramLink } from 'Config';

export default function Navbar(): JSX.Element {
  const [argnav, setArgNav] = useState('');

  const handleScroll = () => {
    if (window.scrollY > 20) {
      setArgNav(
        'shadow-sm  bg-white/40' +
          ' backdrop-blur-md border-b border-gray-100/50'
      );
    } else {
      setArgNav('');
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`fixed w-full ${argnav} z-50 top-0  
    transition-all duration-300 ease-in-out`}>
      <div
        className="container-0 flex gap-2 items-center 
        justify-between py-2.5">
        <div className="flex-1 grow w-full">
          <img alt="qwestive" src={QwestiveLargeBlack} className="max-h-8" />
        </div>
        <div
          className="flex gap-1 sm:gap-2 md:gap-4 
        items-center">
          {/* Twitter Logo */}
          <a href={twitterLink} className=" group">
            <div className="relative group-hover:scale-110 snappy-transition">
              <div className="relative h-8 w-8 text-blue-600">
                {TwitterIcon}
              </div>
            </div>
          </a>

          {/* Discord Logo */}
          <a href={discordLink} className="group">
            <div
              className="relative group-hover:scale-110 
            transition ease-in-out delay-75 duration-100">
              <div className="relative h-8 w-8 text-indigo-600">
                {DiscordIcon}
              </div>
            </div>
          </a>
          {/* Telegram Logo */}
          <a href={telegramLink} className=" group">
            <div
              className="relative group-hover:scale-110 
                snappy-transition">
              <div className="relative h-8 w-8 text-cyan-600">
                {TelegramIcon}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
