import React, { useState } from 'react';
import { TwitterIcon, DiscordIcon, TelegramIconSm } from 'assets/SocialIcons';
import { twitterLink, discordLink, telegramLink } from 'Config';

/* eslint-disable */
const navigation = [
  {
    name: 'Twitter',
    href: twitterLink,
    icon: TwitterIcon,
  },
  {
    name: 'Discord',
    href: discordLink,
    icon: DiscordIcon,
  },
  {
    name: 'Telegram',
    href: telegramLink,
    icon: TelegramIconSm,
  },
];
export default function FooterSection(): JSX.Element {
  const [dateNow] = useState(new Date().getFullYear());
  return (
    <footer className=" backdrop-blur-none">
      <div className="container-0 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-gray-400 hover:text-gray-500">
              <span className="sr-only">{item.name}</span>
              <div className="h-6 w-6" aria-hidden="true">
                {item.icon}
              </div>
            </a>
          ))}
        </div>
        <div className="mt-5 md:mt-0 md:order-1">
          <p className="text-center text-base text-gray-400">
            &copy; {dateNow} Qwestive, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
