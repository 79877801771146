import { TrashIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import React from 'react';
import ClassNamesLogic from 'functions/Util/ClassNamesLogic';
import { ICommunityFormError, ICommunityForm } from 'types/types';

type Props = {
  formInput: ICommunityForm;
  setFormInput: React.Dispatch<React.SetStateAction<ICommunityForm>>;
  formError: ICommunityFormError;
  setFormError: React.Dispatch<React.SetStateAction<ICommunityFormError>>;
};

/* eslint-disable react/no-array-index-key */

export default function TokenInput({
  formInput,
  setFormInput,
  formError,
  setFormError,
}: Props): JSX.Element {
  function handleAddAToken() {
    const newList = formInput.tokens;
    newList.push({ name: '', tokenAddress: '' });
    setFormInput((prev) => ({
      ...prev,
      tokens: newList,
    }));
  }

  function handleRemoveToken(idx: number) {
    // const newList = formInput.tokens;
    setFormInput((prev) => {
      const temp = prev.tokens.filter((item, id) => id !== idx);
      return { ...prev, tokens: temp };
    });
  }

  function handleUpateName(idx: number, value: string) {
    setFormInput((prev) => {
      const temp = prev.tokens;
      temp[idx].name = value;
      return { ...prev, tokens: temp };
    });
    setFormError((prev) => ({
      ...prev,
      tokens: false,
    }));
  }

  function handleUpateAddress(idx: number, value: string) {
    setFormInput((prev) => {
      const temp = prev.tokens;
      temp[idx].tokenAddress = value;
      return { ...prev, tokens: temp };
    });
    setFormError((prev) => ({
      ...prev,
      tokens: false,
    }));
  }

  return (
    <div>
      <div className="text-xl font-bold text-gray-900 text-center mt-6">
        Chose the tokens you want us to generate the data from.
      </div>
      <div className="text-lg font-semibold text-gray-500 text-center mb-5">
        For fungible tokens, enter the token mint address. For non-fungible
        token collection enter the creator wallet address of the candy machine
        ID
      </div>
      <div className="md:grid grid-cols-12 text-lg hidden">
        <div className="form-labels col-span-4">Token name</div>
        <div className="form-labels col-span-7 -ml-3">Token address</div>
      </div>
      <div className="divide-y divide-gray-400 -mt-3">
        {formInput.tokens.map((token, idx) => (
          <div key={idx} className="flex items-center gap-2 py-4">
            <div
              className="w-full md:grid grid-cols-7 
            md:grid-cols-12 gap-2">
              <div className=" col-span-4">
                <p className="block md:hidden form-labels">Token name</p>
                <input
                  type="text"
                  name="tokenName"
                  id="tokenName"
                  autoComplete="nope"
                  className="border-gray-300
                  border text-field-input py-3 px-4 block
                    w-full shadow-sm rounded-md"
                  value={token.name}
                  onChange={(e) => {
                    handleUpateName(idx, e.target.value);
                  }}
                />
              </div>
              <div className="col-span-8">
                <p className="block md:hidden form-labels">Token address</p>
                <input
                  type="text"
                  name="token"
                  id="token"
                  autoComplete="nope"
                  className="border-gray-300
                  border text-field-input py-3 px-4 block
                    w-full shadow-sm rounded-md"
                  value={token.tokenAddress}
                  onChange={(e) => {
                    handleUpateAddress(idx, e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <button
                type="button"
                className="text-gray-900 hover:text-red-700 flex justify-end"
                onClick={() => handleRemoveToken(idx)}
                disabled={idx === 0}>
                <TrashIcon
                  className={ClassNamesLogic(
                    idx === 0 ? ' text-transparent' : '',
                    'h-7'
                  )}
                />
              </button>
            </div>
          </div>
        ))}
      </div>
      {formError.tokens && (
        <div
          className="flex items-center gap-1 
              text-red-600 font-semibold">
          <span>
            <ExclamationCircleIcon className="h-5" />
          </span>
          Please provide at least one token
        </div>
      )}
      <div className="flex justify-end">
        <button
          type="button"
          className="button-action p-3 mt-4"
          onClick={handleAddAToken}
          disabled={formInput.tokens.length >= 6}>
          + add a token or NFT
        </button>
      </div>
    </div>
  );
}
