import React, { useState } from 'react';
import { ICommunityFormError, ICommunityForm } from 'types/types';
import ClassNamesLogic from 'functions/Util/ClassNamesLogic';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import SubmitFormFunction from 'services/Firebase/SubmitFormFunction';
import { toast } from 'react-toastify';
import Spinner from 'components/Spinner';
import TokenInput from './TokenInput';

export default function CsvRequestForm(): JSX.Element {
  const [formInput, setFormInput] = useState<ICommunityForm>({
    communityName: '',
    tokens: [{ name: '', tokenAddress: '' }],
    email: '',
    discord: '',
    message: '',
  });
  const [formError, setFormError] = useState<ICommunityFormError>({
    communityName: false,
    tokens: false,
    email: false,
    discord: false,
    message: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDone, setIsSubmitDone] = useState(false);

  function testEmail(email: string) {
    /* eslint-disable no-useless-escape */
    return (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
      email !== ''
    );
  }

  function isInputValid() {
    let hasError = false;
    // communityName
    if (formInput.communityName === '') {
      setFormError((prev) => ({
        ...prev,
        communityName: true,
      }));
      hasError = true;
    }

    let tokensHasError = true;
    formInput.tokens.forEach((token) => {
      if (token.name !== '' && token.tokenAddress !== '') {
        tokensHasError = false;
      }
    });
    if (tokensHasError) {
      setFormError((prev) => ({
        ...prev,
        tokens: true,
      }));
      hasError = true;
    }

    // email dicord
    const emailCorrect = testEmail(formInput.email);
    if (!emailCorrect && formInput.discord === '') {
      setFormError((prev) => ({
        ...prev,
        discord: true,
        email: true,
      }));
      hasError = true;
    }
    return !hasError;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setIsSubmitting(true);
    event.preventDefault();
    if (isInputValid()) {
      try {
        await SubmitFormFunction(formInput);
        setIsSubmitDone(true);
      } catch (error: any) {
        toast.error(error?.message);
      }
    }
    setIsSubmitting(false);
  }

  return (
    <div>
      <div className="py-6 px-6 sm:px-10 lg:col-span-2 xl:p-12">
        <form onSubmit={handleSubmit} className="mt-3 space-y-4">
          {/* Community name */}
          <div>
            <p className="form-labels">Community name</p>
            <div className="mt-1">
              <input
                type="text"
                name="nickname"
                id="nickname"
                autoComplete="nickname"
                className={ClassNamesLogic(
                  formError.communityName
                    ? 'border-red-400 border-2'
                    : 'border-gray-300',
                  ' border text-field-input py-3 px-4 block' +
                    ' w-full shadow-sm rounded-md'
                )}
                value={formInput.communityName}
                onChange={(e) => {
                  setFormInput((prevState) => ({
                    ...prevState,
                    communityName: e.target.value,
                  }));
                  setFormError((prevState) => ({
                    ...prevState,
                    communityName: false,
                  }));
                }}
              />
            </div>
            {formError.communityName && (
              <div
                className="flex items-center gap-1 
              text-red-600 font-semibold">
                <span>
                  <ExclamationCircleIcon className="h-5" />
                </span>
                Please provide a valid name
              </div>
            )}
          </div>

          <div>
            <TokenInput
              formInput={formInput}
              setFormInput={setFormInput}
              formError={formError}
              setFormError={setFormError}
            />
          </div>

          {/* Email */}
          <div>
            <p className="form-labels">Email</p>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                className={ClassNamesLogic(
                  formError.email
                    ? 'border-red-400 border-2'
                    : 'border-gray-300',
                  ' border text-field-input py-3 px-4 block' +
                    ' w-full shadow-sm rounded-md'
                )}
                value={formInput.email}
                onChange={(e) => {
                  setFormInput((prevState) => ({
                    ...prevState,
                    email: e.target.value,
                  }));
                  setFormError((prevState) => ({
                    ...prevState,
                    email: false,
                    discord: false,
                  }));
                }}
              />
            </div>
            {formError.email && (
              <div
                className=" flex items-center gap-1 
              text-red-600 font-semibold">
                <span>
                  <ExclamationCircleIcon className="h-5" />
                </span>
                Please provide a valid email or a discord/telegram username
              </div>
            )}
          </div>
          {/* Discord */}
          <div>
            <div className="flex justify-between">
              <p className="form-labels">Discord/Telegram</p>
              <span id="discord-optional" className="text-sm text-gray-500">
                Optional
              </span>
            </div>
            <div className="mt-1">
              <input
                type="text"
                name="username"
                id="username"
                autoComplete="username"
                className={ClassNamesLogic(
                  formError.discord
                    ? 'border-red-400 border-2'
                    : 'border-gray-300',
                  ' border text-field-input py-3 px-4 block' +
                    ' w-full shadow-sm rounded-md'
                )}
                aria-describedby="phone-optional"
                value={formInput.discord}
                onChange={(e) => {
                  setFormInput((prevState) => ({
                    ...prevState,
                    discord: e.target.value,
                  }));
                  setFormError((prevState) => ({
                    ...prevState,
                    email: false,
                    discord: false,
                  }));
                }}
              />
            </div>
            {formError.discord && (
              <div
                className=" flex items-center gap-1 
              text-red-600 font-semibold ">
                <span>
                  <ExclamationCircleIcon className="h-5" />
                </span>
                Please provide a valid email or a discord/telegram username
              </div>
            )}
          </div>
          {/* Message */}
          <div>
            <div className="flex justify-between">
              <p className="form-labels">Message</p>
              <span id="message-max" className="text-sm text-gray-500">
                Optional, max. 500 characters
              </span>
            </div>
            <div className="mt-1">
              <textarea
                id="message"
                name="message"
                rows={1}
                maxLength={500}
                className="text-field-input py-3 px-4 block w-full rounded-md
                shadow-sm border border-gray-300"
                aria-describedby="message-max"
                value={formInput.message}
                onChange={(e) =>
                  setFormInput((prevState) => ({
                    ...prevState,
                    message: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          {/* Submit Button */}
          <div className="mt-4 flex justify-end">
            <button
              type="submit"
              className="relative 
              rounded-3xl px-6 p-3
              shadow-md text-white font-semibold
              bg-gradient-to-r from-pink-600 
              to-purple-600
              hover:from-pink-700 
              hover:to-purple-900
              hover:ring-2 
              hover:ring-inset 
              hover:ring-purple-500 hover:ring-opacity-30
              focus:ring-2 focus:ring-inset 
              focus:ring-purple-500 
              focus:ring-opacity-100
              focus:outline-none
              disabled:opacity-50"
              disabled={isSubmitting || isSubmitDone}>
              {isSubmitting && (
                <div className="flex items-center gap-2">
                  <Spinner classExtend="h-4" /> submitting{' '}
                </div>
              )}
              {!isSubmitting && isSubmitDone && (
                <div>Submitted, we will get in touch soon !</div>
              )}
              {!isSubmitting && !isSubmitDone && <div>Submit</div>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
