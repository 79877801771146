import React from 'react';
import Navbar from 'components/Navbar/Navbar';
import { bgGraph } from 'assets/DecorationAssets/DecorationAssets';
import FooterSection from 'components/FooterSection/FooterSection';
import CsvRequestForm from 'components/CsvRequestForm/CsvRequestForm';

export default function HomePage(): JSX.Element {
  return (
    <div className="main-background relative">
      <Navbar />
      <div className="fixed top-[12%] left-[0%] w-screen object-fill">
        <img
          alt="bg graph"
          src={bgGraph}
          className="object-fill transform scale-[1.4] w-[100%]"
        />
      </div>
      <div className="container-0">
        <div
          className="backdrop-blur-none mt-24 leading-[1.2]
          text-5xl text-center max-w-5xl mx-auto 
          font-black">
          Request your comprehensive community data.
        </div>
        <div
          className="backdrop-blur-none mt-5
          text-2xl text-center max-w-3xl mx-auto 
          text-gray-500 font-medium">
          Enter your community address on Solana and receive within 24h a
          detailed csv containing your community data.
        </div>
        <div className="mt-16 card-blured-bg rounded-2xl max-w-3xl mx-auto">
          <CsvRequestForm />
        </div>
        <div className="mt-10">
          <FooterSection />
        </div>
      </div>
    </div>
  );
}
