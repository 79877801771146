import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import HomePage from 'pages/HomePage';
import 'react-toastify/dist/ReactToastify.css';
import 'style/Components/toastify.css';
import 'style/global.css';

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <HomePage />
    </BrowserRouter>
  );
}
