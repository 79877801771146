import { httpsCallable } from 'firebase/functions';
import { ICommunityForm } from 'types/types';
import { FirebaseFunctions } from './FirebaseConfig';

export default async function SubmitFormFunction(
  formInput: ICommunityForm
): Promise<void> {
  const newObject = {} as any;
  newObject.communityName = formInput.communityName;

  formInput.tokens.forEach((tokens, idx) => {
    if (idx <= 6) {
      newObject[`tokenName${idx}`] = tokens.name;
      newObject[`tokenAddress${idx}`] = tokens.tokenAddress;
    }
  });
  newObject.email = formInput.email;
  newObject.discord = formInput.discord;
  newObject.message = formInput.message;

  const contactFormSubmission = httpsCallable(
    FirebaseFunctions,
    'contactFormSubmission'
  );

  await contactFormSubmission(newObject);
}
